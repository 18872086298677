/* General page layout */
.profile-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(15deg, #d4af37, #012265);
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Profile Card */
.profile-card {
    background-color: #fff;
    border-radius: 16px;
    width: 100%;
    max-width: 480px;
    padding: 30px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
}


/* Profile Header */
.profile-header {
    margin-bottom: 20px;
}

.profile-header h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 10px;
    font-weight: 600;
    transition: color 0.3s ease;
}

.profile-header h1:hover {
    color: #3d63f2;
}

.role-tag {
    font-size: 1.2em;
    color: #fff;
    background: linear-gradient(90deg, #f39c12, #f1c40f);
    display: inline-block;
    padding: 8px 16px;
    border-radius: 20px;
    font-weight: bold;
}

/* Profile Information */
.profile-info p {
    font-size: 1.1em;
    color: #555;
    margin-bottom: 15px;
    line-height: 1.6;
    font-weight: 400;
}

.profile-info strong {
    font-weight: 600;
    color: #333;
}

/* Profile Footer */
.profile-footer {
    margin-top: 30px;
}

.edit-btn {
    font-size: 1em;
    padding: 10px 20px;
    color: white;
    background-color: #3d63f2;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.edit-btn:hover {
    background-color: #2a4da1;
    transform: translateY(-3px);
}

/* Loading state */
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    color: #888;
    height: 100vh;
}
